import stackdriver from "./stackdriver";

import type { Plugin } from "@manabie-com/mana-std-logger";
import { LogLevel, createManaLogger } from "@manabie-com/mana-std-logger";
import { parseJSON } from "@manabie-com/mana-utils";
import { getLogLevelInSF } from "src/sf/log-level";

declare global {
    interface Window {
        warner?: ReturnType<typeof createManaLogger>;
    }
}

function getUserInfoForTracing() {
    const sessionId = `session: ${window.__MANA__SESSION_ID__ || "unknown"}`;
    const profileString = localStorage.getItem("manabie_PROFILE"); //TODO: @vctqs1: temporary use localStorage directly, should use mana-storage
    if (!profileString) {
        return `user: Unknown/Unauthorized ${sessionId}`;
    }

    const profile = parseJSON<{ userId: string; name: string; schoolIdsList: string[] }>(
        profileString
    );

    if (!profile) {
        return `user: Unknown/Unauthorized ${sessionId}`;
    }

    const user = profile.userId || profile.name; // fallback into name in case cant find user id

    return `user: ${user}; schools: ${profile.schoolIdsList.join(",")} ${sessionId}`;
}

const reportPlugin: Plugin = {
    warn: (...args) => {
        stackdriver.report(args, {
            user: getUserInfoForTracing(),
        });
    },
    error: (...args) => {
        stackdriver.report(args, {
            user: getUserInfoForTracing(),
        });
    },
};

const warner = createManaLogger({
    std: console,
    plugins: [reportPlugin],
    logLevel: () => {
        if (window?.location?.hostname === "localhost") return LogLevel.DEBUG;

        if (import.meta.env.VITE_SF) {
            return getLogLevelInSF();
        }

        if (import.meta.env.VITE_ENV === "production") {
            return LogLevel.ERROR;
        }

        if (import.meta.env.VITE_ENV === "uat" || import.meta.env.VITE_ENV === "preproduction") {
            return LogLevel.WARN;
        }

        return LogLevel.DEBUG;
    },
    teamName: "root-shell",
});

export function enableLogger() {
    if (typeof window !== "undefined" && !window.warner) {
        window.warner = warner;

        window.addEventListener("error", (e) => {
            warner.error("Unhandled error, resource fetched fail", e.error);
        });
    }
}

export default warner;
